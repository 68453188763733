<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.75 6C6.01522 6 6.26957 5.89464 6.45711 5.70711C6.64464 5.51957 6.75 5.26522 6.75 5V2H15.586L17.75 4.163V5C17.75 5.26522 17.8554 5.51957 18.0429 5.70711C18.2304 5.89464 18.4848 6 18.75 6C19.0152 6 19.2696 5.89464 19.4571 5.70711C19.6446 5.51957 19.75 5.26522 19.75 5V3.749C19.7499 3.48381 19.6446 3.22949 19.457 3.042L17 0.587C16.6246 0.212461 16.1163 0.00147089 15.586 0L6.75 0C6.21957 0 5.71086 0.210714 5.33579 0.585786C4.96071 0.960859 4.75 1.46957 4.75 2V5C4.75 5.26522 4.85536 5.51957 5.04289 5.70711C5.23043 5.89464 5.48478 6 5.75 6Z"
      fill="#282A4C"
    />
    <path
      d="M21.5 7H2.5C1.83696 7 1.20107 7.26339 0.732233 7.73223C0.263392 8.20107 0 8.83696 0 9.5L0 14C0 14.663 0.263392 15.2989 0.732233 15.7678C1.20107 16.2366 1.83696 16.5 2.5 16.5H5V22.75C5.00026 23.0814 5.13205 23.3992 5.36641 23.6336C5.60077 23.868 5.91856 23.9997 6.25 24H18.25C18.5814 23.9997 18.8992 23.868 19.1336 23.6336C19.368 23.3992 19.4997 23.0814 19.5 22.75V16.5H21.5C21.8283 16.5 22.1534 16.4353 22.4567 16.3097C22.76 16.1841 23.0356 15.9999 23.2678 15.7678C23.4999 15.5356 23.6841 15.26 23.8097 14.9567C23.9353 14.6534 24 14.3283 24 14V9.5C24 9.1717 23.9353 8.84661 23.8097 8.54329C23.6841 8.23998 23.4999 7.96438 23.2678 7.73223C23.0356 7.50009 22.76 7.31594 22.4567 7.1903C22.1534 7.06466 21.8283 7 21.5 7ZM3.5 11.5C3.30222 11.5 3.10888 11.4414 2.94443 11.3315C2.77998 11.2216 2.65181 11.0654 2.57612 10.8827C2.50043 10.7 2.48063 10.4989 2.51921 10.3049C2.5578 10.1109 2.65304 9.93275 2.79289 9.79289C2.93275 9.65304 3.11093 9.5578 3.30491 9.51921C3.49889 9.48063 3.69996 9.50043 3.88268 9.57612C4.06541 9.65181 4.22159 9.77998 4.33147 9.94443C4.44135 10.1089 4.5 10.3022 4.5 10.5C4.5 10.7652 4.39464 11.0196 4.20711 11.2071C4.01957 11.3946 3.76522 11.5 3.5 11.5ZM17.5 21.5C17.5 21.6326 17.4473 21.7598 17.3536 21.8536C17.2598 21.9473 17.1326 22 17 22H7.5C7.36739 22 7.24021 21.9473 7.14645 21.8536C7.05268 21.7598 7 21.6326 7 21.5V12.457C7 12.3244 7.05268 12.1972 7.14645 12.1034C7.24021 12.0097 7.36739 11.957 7.5 11.957H17C17.1326 11.957 17.2598 12.0097 17.3536 12.1034C17.4473 12.1972 17.5 12.3244 17.5 12.457V21.5Z"
      fill="#282A4C"
    />
    <path
      d="M15 14.749H9.5C9.30109 14.749 9.11032 14.828 8.96967 14.9687C8.82902 15.1093 8.75 15.3001 8.75 15.499C8.75 15.6979 8.82902 15.8887 8.96967 16.0294C9.11032 16.17 9.30109 16.249 9.5 16.249H15C15.1989 16.249 15.3897 16.17 15.5303 16.0294C15.671 15.8887 15.75 15.6979 15.75 15.499C15.75 15.3001 15.671 15.1093 15.5303 14.9687C15.3897 14.828 15.1989 14.749 15 14.749Z"
      fill="#282A4C"
    />
    <path
      d="M13.25 18.249H9.5C9.30109 18.249 9.11032 18.328 8.96967 18.4687C8.82902 18.6093 8.75 18.8001 8.75 18.999C8.75 19.1979 8.82902 19.3887 8.96967 19.5294C9.11032 19.67 9.30109 19.749 9.5 19.749H13.25C13.4489 19.749 13.6397 19.67 13.7803 19.5294C13.921 19.3887 14 19.1979 14 18.999C14 18.8001 13.921 18.6093 13.7803 18.4687C13.6397 18.328 13.4489 18.249 13.25 18.249Z"
      fill="#282A4C"
    />
  </svg>
</template>
